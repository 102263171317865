import React from 'react';
import { slide as Menu } from 'react-burger-menu'
import MenuButton from './MenuButton.js'

var images = require.context('./assets', true);

class MainMenu extends React.Component {

    constructor (props) {
        super(props)
        this.state = {
          menuOpen: false
        }
    }

    render = () => {
        return (
            <Menu isOpen={this.state.menuOpen} disableAutoFocus onStateChange={(state) => this.handleStateChange(state)}>
                <img src={images("./new-logo-opacity.png")} alt={"Yamas De Bilt Logo"} />
                <MenuButton menu={this} text="Home" link="everything-container"></MenuButton>
                <MenuButton menu={this} text="Over Ons" link="overons-container"></MenuButton>
                <MenuButton menu={this} text="Menu" link="menu-container"></MenuButton>
                <MenuButton menu={this} text="Catering" link="catering-container"></MenuButton>
                <MenuButton menu={this} text="Locatie &amp; Contact" link="contact-container"></MenuButton>
                <MenuButton menu={this} text="Reserveren" link="reserveren-container"></MenuButton>
            </Menu>
        )
    }

    // This keeps your state in sync with the opening/closing of the menu
    // via the default means, e.g. clicking the X, pressing the ESC key etc.
    handleStateChange (state) {
        this.setState({menuOpen: state.isOpen})  
    }

    closeMenu = () => {
        this.setState({
            menuOpen: false
        })
    }
}

export default MainMenu;