import React from 'react';

import {categories} from './assets/afhaalmenu.json';

var images = require.context('./assets', true);

class Contents {
    constructor() {
        /*
        this.aanbiedingContent = (
            <div>
                <div className="title">
                    <h1>Te Koop</h1>
                </div>
        
                <div id="aanbieding-image-container">
                    <img src={images("./aanbiedingkaart.png")} alt="Yamas De Bilt Aanbiedingkaart"/>
                </div>
                <div>
                    <p><br />voor meer info bel <a href="tel:0302218765">030 221 87 65</a></p>
                </div>
            </div>
        );
        */

       this.aanbiedingContent = (
            <div>
                <div className="title">
                    <h1>Aankondiging</h1>
                </div>
        
                <div style={{position: 'relative', left: '10%', width: '80%'}}>
                    <p>
                    Lieve Gasten van Yamas, <br/>
                    <br/>
                    Wat hebben we u gemist. We zijn blij u eindelijk vanaf 1 juni weer te kunnen verwelkomen voor een diner in ons restaurant.<br/><br/> 
                    Het aantal gasten is beperkt tot 30 dus leg tijdig uw plek vast en voorkom teleurstelling.<br/><br/>
                    Ondertussen heeft u thuis van onze gerechten kunnen genieten. We hebben goed nieuws de afhaal optie blijft! Onlangs hebben we deze zelfs uitgebreid met een bbq arrangement, om in uw tuin of balkon in de Griekse vakantiesfeer te komen. Bekijk al onze heerlijke take away opties.<br/><br/>
                    Uiteraard houden wij ons strikt aan de richtlijnen van het RIVM. Voor uw en onze gezondheid vindt u hieronder richtlijnen die voor uw bezoek gelden. <br/><br/>
                    Komt u snel weer met ons proosten? YAMAS <br/><br/>
                    Wij verheugen er ons op.<br/><br/>
                    Jan, Roos en team Yamas <br/><br/>
                    </p>
                </div>

                <h3>Richtlijnen voor uw reservering en bezoek aan Grieks Taverne Yamas</h3>
                <div style={{position: 'relative', left: '10%', width: '80%'}}>
                    <p>
                        Wij mogen u alleen ontvangen als u van tevoren reserveert. Voor reserveringen zijn wij telefonisch bereikbaar van woensdag tot zondag vanaf 14:00 uur op telefoonnummer 030-2218765 of per mail info@grieksetavernayamas.nl <br/><br/>
                        Het maximaal aantal gasten tegelijkertijd mag maximaal 30 bedragen. <br/><br/>
                        Alle tafels hebben 1,5 meter afstand van elkaar. Wij gaan uit van 1 tafel per reservering. Mocht de samenstelling van uw gezelschap uit meerdere huishoudens bestaan, geeft u dit dan aan bij uw reservering zodat wij de tafelopstelling hierop aan kunnen passen. <br/><br/>
                        Heeft u of een van uw gasten verkoudheidsklachten (RIVM basis regels), dan dient u de reservering te annuleren. Bij binnenkomst moeten wij u conform de richtlijnen een aantal vragen stellen omtrent uw gezondheid. Tevens verzoeken wij u desinfectiemiddelen te gebruiken die wij daar voor u beschikbaar hebben.                    </p>
                </div>
                
            </div>
        );

        this.afhalenContent = (
            <div>
                <div className="title">
                    <h1>Afhalen</h1>
                </div>
        
                <div style={{position: 'relative', left: '10%', width: '80%'}}>
                    <p>
                        Afhalen is mogelijk van woensdag tot zondag.<br />
                        Bestellen is telefonisch mogelijk via telefoonnummer <a href="tel:0302218765"><span itemProp="telephone" content="+0302218765">030 221 87 65</span></a>.
                    </p>
                </div>
                
                <div className="title">
                    <h1>Afhaalmenu</h1>
                </div>
                <p style={{width: "100%"}}>Het is ook mogelijk om van het reguliere <a href="#menu-container">menu</a> te bestellen</p>
                <div className="row">
                    { categories.map(
                        (category, index) => {
                            
                            return (
                                <div key={index} className="row" style={{textAlign: "left"}}>
                                    <div className="col-12 menu-title">{category.title}</div>
                                    <div className="col-12"><p>{category.note}</p></div>
                                    {
                                        category.meals.map(
                                            (meal, index) => {

                                                return this.itemToJSX(meal.name, meal.price, meal.description, index);
                                            }
                                        )
                                    }
                                </div>
                            );
                        }
                    )}

                    <br /><br /><br />
                </div>
            </div>
        );

        this.openingstijdenOverOnsContent = (
            <div className='row'>
                <div className="col-12 col-lg-6">
                    <div className='title'>
                        <h1>OPENINGSTIJDEN</h1>
                    </div>
                    <p>
                        Maandag en dinsdag gesloten<br />
                        (m.u.v. feestdagen)<br />
                        Op feestdagen gelden geen acties en kortingskaarten<br />
                        woensdag tot en met zondag<br />
                        keuken: 17:00 - 21:00<br />
                        zaak: 14:00 tot in de laatste uurtjes<br />
                    </p>
                </div>
                <div className="col-12 col-lg-6 background-fill photo-inline" style={{minHeight: '300px', backgroundImage: 'url(' + images('./yamas-nieuw-foto-1.jpeg') + ')'}}></div>
                <div className="col-12 col-lg-6 background-fill" style={{backgroundImage: 'url(' + images('./yamas-nieuw-foto-1.jpeg') + ')'}}></div>
                <div className="col-12 col-lg-6">
                    <div className='title'>
                        <h1>OVER&nbsp;ONS</h1>
                    </div>
                    <p>
                        Welkom bij Griekse taverna Yamas!<br />
                        <br />
                        Vanaf 2018 gaan wij er alles aan doen de griekse sfeer naar De Bilt te brengen, 
                        net zoals restaurant Alexandros dat 5 jaar heeft gedaan.
                        Wij werken ook samen met de plaatselijke slagerij Van Loo.<br />
                        <br />
                        Wij willen u de mogelijkheid bieden om de warme en gezellige sferen uit Griekenland mee te maken tijdens het eten,
                        en te proeven uit onze uitgebreide gerechten.<br />
                        <br />
                        Op onze kaart zult u een groot aantal gerechten vinden
                        waaronder vlees, vis, vegetarische, en natuurlijk klassieke Griekse gerechten.
                        Ook voor de kleintjes vindt u gerechten op ons kindermenu.
                        <br />
                        <br />
                        Buiten de kaart om serveren wij ook vlees- en visgerechten, uw obers en serveersters informeren u graag.
                        Heeft u allergie&euml;n of dieetwensen? Vraag naar de mogelijkheden.<br />
                        <br />
                        Kortom, laat u bij het uit eten gaan verrassen door de heerlijke, authentieke Griekse gerechten in onze taverna!<br />
                        <br />
                        <i>Bent u tevreden, vertel het een ander! Bent u niet tevreden, laat het ons weten
                        zodat wij het u wel naar wens kunnen maken.</i><br />
                    </p>
                </div>
            </div>
        )

        this.cateringContent = (
            <div className='row'>
                <div className='title'>
                    <h1>CATERING</h1>
                </div>
                Griekse taverna Yamas verzorgt ook uw catering, waar dan ook!<br />
                <br />
                Wij zijn er voor bedrijven en particulieren. Het organiseren van een catering kent bij ons geen grenzen.
                Het maakt ons niet uit of het om 10 of om 300 personen gaat.<br />
                <br />
                Wij kunnen desgewenst ook live muziek verzorgen bij u op locatie en dat kan zelfs in uw eigen huiskamer!<br />
                Natuurlijk is het ook mogelijk de zaak af te huren voor feestjes of recepties.<br />
                <br />
                Neem vrijblijvend contact met ons op om de mogelijkheden te bespreken. Ten slotte is catering maatwerk.<br />
                <br />
            </div>
        )

        this.contactAndLocationContent = (
            <div id="locatie-en-contact" className='row'>
                <div className="col-12 col-lg-6">
                    <div className='title'>
                        <h1>LOCATIE</h1>
                    </div>
                    <iframe title="Yamas De Bilt locatie" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d578.1950357041779!2d5.181648061434622!3d52.11057596391638!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c668fa8b7e338f%3A0xf0b1e31685699991!2sHessenweg+176%2C+3731+JN+De+Bilt%2C+Netherlands!5e0!3m2!1sen!2sde!4v1509029888563" style={{width:"100%", height:"400px", frameborder:"0", border: "0px", allowfullscreen: true}}></iframe>
                </div>
                <div className="col-12 col-lg-6">
                    <div className='title'>
                            <h1>CONTACT</h1>
                    </div>
                    <div className='row align-items-center justify-content-around'>
                        <div className="col-12">
                            <img className="icon" src={images("./marker.png")} alt="locatie" /><br />
                            <div itemProp="address" itemScope itemType="https://schema.org/PostalAddress">
                                <span itemProp="streetAddress">
                                    Hessenweg 176, <br />
                                    3731JN, De Bilt<br /><br />
                                </span>
                            </div>
                        </div>
                        <div className="col-12">
                            <img className="icon" src={images("./time.png")} alt="openingsuren" /><br />
                            <span itemProp="openingHours" content="Tu,We,Th,Fr,Sa,Su 17:00-21:00">
                                Maandag en dinsdag gesloten<br />
                                (m.u.v. feestdagen)<br />
                                Op feestdagen gelden geen acties en kortingskaarten<br />
                                woensdag tot en met zondag<br />
                                keuken: 17:00 - 21:00<br />
                                zaak: 14:00 tot in de laatste uurtjes<br />
                            </span><br /><br />
                        </div>
                        <div className="col-12">
                            <img className="icon" src={images("./mail.png")} alt="mailadres" /><br />
                            <a href="mailto:info@grieksetavernayamas.nl"> info@grieksetavernayamas.nl</a><br /><br />
                        </div>
                        <div className="col-12">
                            <img className="icon" src={images("./phone.png")} alt="telefoon nummer" /><br />
                            <a href="tel:0302218765"><span itemProp="telephone" content="+0302218765">030 221 87 65</span></a><br /><br />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    itemToJSX = (name, price, description, index) => {
        return (
            <div key={index} className="col-12 col-lg-6">
                <span className="item">{name}</span>
                <span className="price">{price}</span><br />
                <p>{description}</p>
            </div>
        )
    }
}

export default Contents;