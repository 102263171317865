import React from 'react';
//import axios from 'axios';
import emailjs from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";

var images = require.context('./assets', true);

class ReviewForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            eten: 0,
            service: 0,
            sfeer: 0,
            prijs: 0,
        }
    }

    render = () => {
        const recaptchaRef = React.createRef();
        
        return (
            <div id="reviews" className='row'>
                <div className='title'>
                    <h1>REVIEW</h1>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <form method="POST" id="reviewForm" className="col-12" action="review.php">
                            <div className="row">
                                <div className="col-sm-12 col-md-6">
                                    <b>Eten &amp; Drinken</b>
                                    <input className="starvalue" type="number" id="eten" name="eten" required="" /><br />
                                    <span>
                                        {
                                            [1,2,3,4,5].map((n, index) => {
                                                let colour = this.state.eten >= n ? "yellow": "grey";

                                                return <img key={index} alt="star" id={"eten_" + n} className="star" src={images('./' + colour + '_star.png')} onClick={() => this.setState({eten: n})} />
                                            })
                                        }
                                    </span><br /><br />
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <b>Service</b>
                                    <input className="starvalue" type="number" id="service" name="service" required="" /><br />
                                    <span>
                                        {
                                            [1,2,3,4,5].map((n, index) => {
                                                let colour = this.state.service >= n ? "yellow": "grey";

                                                return <img key={index} alt="star" id={"service_" + n} className="star" src={images('./' + colour + '_star.png')} onClick={() => this.setState({service: n})} />
                                            })
                                        }
                                    </span><br /><br />
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <b>Sfeer</b>
                                    <input className="starvalue" type="number" id="sfeer" name="sfeer" required="" /><br />
                                    <span>
                                        {
                                            [1,2,3,4,5].map((n, index) => {
                                                let colour = this.state.sfeer >= n ? "yellow": "grey";

                                                return <img key={index} alt="star" id={"sfeer_" + n} className="star" src={images('./' + colour + '_star.png')} onClick={() => this.setState({sfeer: n})} />
                                            })
                                        }
                                    </span><br /><br />
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <b>Prijs / kwaliteit</b>
                                    <input className="starvalue" type="number" id="prijs" name="prijs" required="" /><br />
                                    <span>
                                        {
                                            [1,2,3,4,5].map((n, index) => {
                                                let colour = this.state.prijs >= n ? "yellow": "grey";

                                                return <img key={index} alt="star" id={"prijs_" + n} className="star" src={images('./' + colour + '_star.png')} onClick={() => this.setState({prijs: n})} />
                                            })
                                        }
                                    </span><br /><br />
                                </div>
                                <div className="col-sm-12">
                                    <b>Naam</b><span className='red'>*</span><br />
                                    <input type="text" name="reviewer_name" required="" /><br /><br />
                                </div>
                                <div className="col-sm-12">
                                    <b>E-mailadres</b><span className='red'></span><br />
                                    <input type="text" name="reviewer_email" required="" /><br /><br />
                                </div>
                                <div className="col-sm-12">
                                    <b>Deel uw ervaring:</b><br />
                                    <textarea maxLength="100" rows="4" cols="50" name="reviewer_message" required=""></textarea><br /><br />
                                </div>
                                <div className="col-sm-12 centered">
                                    <input type="button" name="send" onClick={() => recaptchaRef.current.execute()} value="VERSTUREN" /><br /><br /><br />
                                </div>
                            </div>

                            <ReCAPTCHA
                                ref={recaptchaRef}
                                size="invisible"
                                sitekey="6Le8W6IUAAAAAHFLuMRWmLHyN6iDsO38lJWxJE1g"
                                onChange={() => this.sendReview()}
                            />
                        </form>
                    </div>

                    <div className="col-12 col-lg-6 background-fill" style={{backgroundImage: 'url(' + images('./yamas-nieuw-foto-4.jpeg') + ')'}}>
                    
                    </div>
                </div>
            </div>
        )
    }

    sendReview = () => {
        let name = document.getElementsByName("reviewer_name")[0].value;
        let message = document.getElementsByName("reviewer_message")[0].value;
        let mail = document.getElementsByName("reviewer_email")[0].value;

        if (this.state.eten === 0 || this.state.service === 0 || this.state.sfeer === 0 || this.state.prijs === 0 || name === "") {
            alert("Niet all verplichte velden zijn ingevuld");
        } else {
            /*
            console.log("sending review");
            console.log("eten: " + this.state.eten);
            console.log("service: " + this.state.service);
            console.log("sfeer: " + this.state.sfeer);
            console.log("prijs: " + this.state.prijs);
            console.log("naam: " + name);
            console.log("mail: " + mail);
            console.log("message: " + message);
            

            let reviewData = {
                eten: this.state.eten,
                service: this.state.service,
                sfeer: this.state.sfeer,
                prijs: this.state.prijs,
                naam: name,
                mail: mail,
                message: message
            };
            */

            emailjs.send("gmail", "review_added", 
                {
                    "eten": this.state.eten,
                    "service": this.state.service,
                    "sfeer": this.state.sfeer,
                    "prijs": this.state.prijs,
                    "naam": name,
                    "mail": mail,
                    "opmerkingen": message},
                    "user_cMhvYmgPP7p3mDjNUpZTa")

            alert("Dank u wel voor het achterlaten van een review!")

            /*
            axios.post("/php/addreview.php", { reviewData })
            .then(res => {
                console.log(res);
                console.log(res.data);
            })
            */
        }
        
    }

}

export default ReviewForm;