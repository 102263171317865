import React from 'react';


class MainContainer extends React.Component {

    render = () => {
        return (
            <div id={this.props.id} className="main-container row">
                <div className="col-sm-0 col-md-2 side"></div>
                <div className="col-sm-12 col-md-8 content">
                    {this.props.content}
                </div>
                <div className="col-sm-0 col-md-2 side"></div>
            </div>
        )
    }

}

export default MainContainer;