import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import {useRoutes} from 'hookrouter';

import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/styles.scss';
import './styles/burger-styles.scss';

ReactGA.initialize('UA-72578995-2');

//ReactDOM.render(<App />, document.getElementById('root'));

const routes = {
    "/": () => <App />
};

const RouteManager = () => {
    const routeResult = useRoutes(routes);
    
    ReactGA.pageview(window.location.pathname + window.location.search)

    return routeResult || <App />;
}

ReactDOM.render(<RouteManager />, document.getElementById('root'));
