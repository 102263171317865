import React from 'react';

var images = require.context('./assets', true);

class Footer extends React.Component {

    render = () => {
        return (
            <div id="footer" className="white-text">
                <span>Griekse taverna Yamas - De Bilt 2017</span>
                <span style={{textAlign: "right", float: "right"}}> Icons from <a href="https://icons8.com/"><img src={images('./icons8.png')} alt="icons van Icons8" /></a></span>
            </div>
        )
    }

}

export default Footer;