import React from 'react';

import MainMenu from './MainMenu';
import Landing from './Landing.js';
import MainContainer from './MainContainer.js';
import Footer from './Footer.js';

import Contents from './Contents.js';
import ReviewForm from './ReviewForm';
import Reviews from './Reviews.js';
import RestaurantMenu from './RestaurantMenu.js';
import Reserveren from './Reserveren.js';

var images = require.context('./assets', true);

var photos = ["eten1_zoomed.jpg", "eten_4.jpg", "rest6.jpg", "yamas-nieuw-foto-4.jpeg", "yamas-nieuw-foto-3.jpeg"];

/* * * * * * * * * *
    TODO LIST

    - review opslaan in db als er op submit geklikt wordt
    - captcha toevoegen bij review en bij reserveer
    - links laten werken vh menu en op de landing

* * * * * * * * * * */

class App extends React.Component {

    constructor() {
        super();
        this.contents = new Contents();
    }

    //<MainContainer id="aanbieding-container" content={this.contents.aanbiedingContent}/>
    render = () => {
        return (
            <div id="everything-container">
                <div id="main-background" />
                <MainMenu />
                <Landing photos={photos} />
                
                <MainContainer id="afhalen-container" content={this.contents.afhalenContent}/>
                <MainContainer id="overons-container" content={this.contents.openingstijdenOverOnsContent}/>
                <div style={{width: '100%', height: '500px'}} />
                <MainContainer id="reviewform-container" content={<ReviewForm />}/>
                <MainContainer id="reviews-container" content={<Reviews />}/>
                <div style={{width: '100%', height: '500px'}} />
                <MainContainer id="menu-container" content={<RestaurantMenu />} />
                <MainContainer id="reserveren-container" content={<Reserveren />}/>
                <MainContainer id="catering-container" content={this.contents.cateringContent} />
                <MainContainer id="contact-container" content={this.contents.contactAndLocationContent} />
                <Footer />
            </div>
        )
    }

    componentDidMount = () => {
        document.addEventListener('scroll', this.onScroll, true);
    }

    onScroll = (e) => {

        let reviewFormContainer = document.getElementById('reviewform-container');
        let reviewsContainer = document.getElementById('reviews-container');

        if(window.scrollY + window.innerHeight < reviewFormContainer.offsetTop + reviewFormContainer.offsetHeight + reviewsContainer.offsetHeight){
            document.getElementById('main-background').style.backgroundImage = 'url(' + images('./eten_2.jpg') + ')';
        }
        if(window.scrollY > reviewFormContainer.offsetTop){
            document.getElementById('main-background').style.backgroundImage = 'url(' + images('./eten_3.jpg') + ')';
        }
        
    }
}

export default App;