import React from 'react';

import {categories} from './assets/restaurantmenu.json';

class RestaurantMenu extends React.Component {

    render = () => {
        return (
            <div>
                <div className='title'>
                    <h1>MENU</h1>
                </div>
                <p style={{width: "100%"}}>Afhalen is bij ons ook mogelijk: zie hiervoor de speciale <a href="#afhalen-container">afhaal menukaart</a></p>
                <p style={{width: "100%"}}>Onze gerechten kunnen pinda's, lactose of andere allergie gevoelige producten bevatten</p>
                    { categories.map(
                        (category, index) => {
                            
                            return (
                                <div key={index} className="row" style={{textAlign: "left"}}>
                                    <div className="col-12 menu-title">{category.title}</div>
                                    {
                                        category.note.split('\n').map(
                                            (note, index) => {
                                                return (<div className="col-12"><p>{ note}</p></div>)
                                            }
                                        )
                                    }
                                    
                                    {
                                        category.meals.map(
                                            (meal, index) => {

                                                return this.itemToJSX(meal.name, meal.price, meal.description, index);
                                            }
                                        )
                                    }
                                </div>
                            );
                        }
                    )}
                
            </div>
        )
    }

    itemToJSX = (name, price, description, index) => {
        return (
            <div key={index} className="col-12 col-lg-6">
                <span className="item">{name}</span>
                <span className="price">{price}</span><br />
                <p>{description}</p>
            </div>
        )
    }
}

export default RestaurantMenu;