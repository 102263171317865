import React from 'react';

class MenuButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            text: props.text,
            link: props.link
        };
    }

    render = () => {
        return (
            <div className="menu-button"
            onClick={() => this.scroll()}>
                <span>{this.state.text}</span>
            </div>
        )
    }

    scroll = () => {
        this.props.menu.closeMenu();
        
        window.scrollTo({
            top: document.getElementById(this.props.link).offsetTop,
            left: 0,
            behavior: 'smooth'
        });
    }
}

export default MenuButton;