import React from 'react';


class Reviews extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            //TODO: reviews: this.getReviews()
            reviews: [
                {   
                    naam: "Petra van den Elshout",
                    datum: "2019-06-19",
                    opmerkingen: "Niet wetende waar wij gingen eten, zeer verrassend, goede kwaliteit van eten, Griekse gastvrijheid"
                }, 
                {   
                    naam: "Ray",
                    datum: "2019-07-18",
                    opmerkingen: "Weer heerlijk gegeten bij jullie. Prima sfeer en bediening. We komen zeker weer terug."
                }
            ]
        }
    }
    

    render = () => {

        let maanden = ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"];

        let datum0 = this.state.reviews[0].datum.split('-');
        if (datum0[1][0] === "0") datum0[1] = datum0[1][1];
        let datum1 = this.state.reviews[1].datum.split('-');
        if (datum1[1][0] === "0") datum1[1] = datum1[1][1];

        return (
            <div id="show-reviews">
                <div className='title'>
                    <h1>REVIEWS</h1>
                </div>
                <div className="row">
                    <div className="review-container col-sm-12 col-md-6">
                        <p><i>{this.state.reviews[0].opmerkingen}</i></p>
                        <span style={{float: "left"}}>{this.state.reviews[0].naam}</span><span style={{float:"right", paddingRight: "20px"}}>{datum0[2] + " " + maanden[datum0[1] - 1] + " " + datum0[0]}</span>
                    </div>
                    <div className="review-container col-sm-12 col-md-6">
                        <p><i>{this.state.reviews[1].opmerkingen}</i></p>
                        <span style={{float: "left"}}>{this.state.reviews[1].naam}</span><span style={{float:"right", paddingRight: "20px"}}>{datum1[2] + " " + maanden[datum1[1] - 1] + " " + datum1[0]}</span>
                    </div>
                </div>
            </div>
        )
    }

    getReviews = () => {
        return null
    }

}

export default Reviews;