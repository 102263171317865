import React from 'react';
//import emailjs from 'emailjs-com';
//import ReCAPTCHA from "react-google-recaptcha";
//import DatePicker from "react-datepicker";
 
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import nl from 'date-fns/locale/nl';
registerLocale('nl', nl)

class Reserveren extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            datumtijd: new Date()
        }
    }

    handleDateChange = date => {
        this.setState({
            datumtijd: date
        });
      };

    render = () => {
        //const recaptchaRef = React.createRef();

        return (
            <div>
                <div className='title'>
                    <h1>RESERVEREN</h1>
                </div>
                
                <p>Voor reserveringen zijn wij telefonisch bereikbaar van woensdag tot zondag vanaf 14:00 uur op telefoonnummer <a href="tel:0302218765"><span itemProp="telephone" content="+0302218765">030 221 87 65</span></a></p>
            </div>
        )
        /*
        return (
            <div>
                <div className='title'>
                    <h1>RESERVEREN</h1>
                </div>
                
                <p>
                    In verband met de Corona maatregelen is het restaurant na 14 oktober
                    alleen nog open van woensdag tot zondag voor afhalen.
                </p>
                <form method="POST" id="reserveerForm" className="col-12">
                    <div className="row">
                        <div className="col-sm-12">
                            <b>Naam</b><span >*</span><br />
                            <input disabled type="text" name="reserve_name" required="" /><br /><br />
                        </div>
                        <div className="col-sm-12">
                            <b>E-mailadres</b><span >*</span><br />
                            <input disabled type="text" name="reserve_email" required="" /><br /><br />
                        </div>
                        <div className="col-sm-12">
                            <b>Telefoonnummer</b><span >*</span><br />
                            <input disabled type="text" name="reserve_phone" required="" /><br /><br />
                        </div>
                        <div className="col-sm-12">
                            <b>Aantal mensen</b><span >*</span><br />
                            <input disabled id="group-size-box" type="text" min="1" name="group_size" required="" /><br /><br />
                        </div>
                        <div className="col-sm-12">
                            <div className=" datetime-container row">
                                <div className="col-sm-6">
                                    <b>Datum</b><span >*</span><br />
                                    <DatePicker
                                        disabled
                                        selected={this.state.datumtijd}
                                        onChange={this.handleDateChange}
                                        locale="nl"
                                        dateFormat="dd-MM-yyyy"
                                        minDate={new Date()}
                                        filterDate={this.isNotMondayOrTuesday}
                                        excludeDates={[new Date(2020, 9, 24), new Date(2020, 9, 10)]}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <b>Tijd</b><span >*</span><br />
                                    <input disabled id="time-input" type="time" name="time-value"></input>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <b>Opmerkingen</b><br />
                            <textarea disabled maxLength="100" rows="4" cols="50" name="reserve_message" required=""></textarea><br /><br />
                        </div>
                        <div className="col-sm-12 centered">
                            <input disabled type="button" name="send" onClick={() => this.sendReservering()} value="RESERVEER" /><br /><br /><br />
                        </div>
                        <p>
                            <i>Let op: uw reservering is pas definitief wanneer u een bevestiginsmail van ons heeft ontvangen.</i>
                        </p>
                    </div>

                    <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        //sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" //test sitekey, alles slaagt
                        sitekey="6Le8W6IUAAAAAHFLuMRWmLHyN6iDsO38lJWxJE1g"
                        onChange={() => this.sendReservering()}
                    />
                </form>
            </div>
        )
        */
    }

    /*
    isNotMondayOrTuesday = (date) => {
        const day = date.getDay();
        return day !== 1 && day !== 2;
    }

    sendReservering = () => {

        let naam = document.getElementsByName("reserve_name")[0].value;
        let mail = document.getElementsByName("reserve_email")[0].value;
        let telefoon = document.getElementsByName("reserve_phone")[0].value;
        let aantal = document.getElementsByName("group_size")[0].value;
        //let datumtijd = document.getElementsByName("date_and_time")[0].value;
        let datum = (this.state.datumtijd === null) ? "" : this.state.datumtijd.toLocaleString('nl', { year: 'numeric', month: 'long', day: 'numeric' });
        let tijd = document.getElementsByName("time-value")[0].value;
        let opmerkingen = document.getElementsByName("reserve_message")[0].value;
        
        let datumtijd = datum + " " + tijd;

        console.log(datumtijd);
        //console.log(datum);
        //console.log(tijd);
        if (naam === "" || mail === "" || telefoon === "" || aantal === "" || datum === "" || tijd === "") {
            alert("Niet alle verplichte velden zijn ingevuld");
        }

        else {
            emailjs.send("gmail", 
                "reservering_added", 
                {
                    "naam":naam,
                    "mail":mail,
                    "telefoon": telefoon,
                    "aantal":aantal,
                    "datumtijd":datumtijd,
                    "opmerkingen":opmerkingen
                },
                "user_cMhvYmgPP7p3mDjNUpZTa")

            alert("Dank u wel voor uw reservering! U hoort zo snel mogelijk van ons terug.")
        }
    }
*/
}

export default Reserveren;