import React from 'react';
import Button from '@material-ui/core/Button';

var images = require.context('./assets', true);

class Landing extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            index: 0,
        }
        
       setTimeout(this.nextPhoto, 10000);
    }

    render = () => {
        return (
            <div id="landing-background">
                <div id="landing" style={{backgroundImage: 'url(' + images("./" + this.props.photos[this.state.index % this.props.photos.length]) + ')'}}></div>
                <div id="inner-landing">
                    <div id="announcement">
                        <img id="hoekje" src={images("./hoekje.png")} alt="Promotie open beide kerstdagen restaurant Yamas De Bilt" />
                    </div>

                    <div id="title">
                        <h1>Yamas - De Bilt</h1>
                    </div>
                    <div className="row buttons">
                        <div className="col-12 col-lg-4 center-block text-center">
                            <Button variant="contained" color="primary" onClick={() => this.scroll("menu-container")}>
                                Menu
                            </Button>
                        </div>
                        <div className="col-12 col-lg-4 center-block text-center">
                            <Button variant="contained" color="primary" onClick={() => this.scroll("reserveren-container")}>
                                Reserveer een tafel
                            </Button>
                        </div>
                        <div className="col-12 col-lg-4 center-block text-center">
                            <Button variant="contained" color="primary" onClick={() => this.scroll("afhalen-container")}>
                                Afhaalkaart
                            </Button>
                        </div>
                    </div>
                    <div id="extra-announcement">
                        <p>
                            Feestdagen geopend <br />
                        </p>
                    </div>
                </div>
                <div style={{height: '100vh'}} />
            </div>
        )
    }

    componentWillUnmount() {
        clearInterval(this.photoInterval);
        clearInterval(this.darkeningInterval);
        clearInterval(this.lighteningInterval);
    }

    nextPhoto = () => {

        let d = 0;

        this.darkeningInterval = setInterval(() => {
            let landing = document.getElementById("landing");

            landing.style.opacity = (1 - 0.01 * d);
            d++;

            if (d >= 100) {
                clearInterval(this.darkeningInterval);

                let newIndex = this.state.index + 1;
                
                this.setState({
                    index: newIndex
                });

                let l = 0;
                this.lighteningInterval = setInterval(() => {
                    landing.style.opacity = (0.01 * l);
                    l++;

                    if (l >= 100) {
                        clearInterval(this.lighteningInterval);
                        setTimeout(this.nextPhoto, 10000);
                    }
                }, 3)
            }
        }, 3);
    }

    scroll = (link) => {
        window.scrollTo({
            top: document.getElementById(link).offsetTop,
            left: 0,
            behavior: 'smooth'
        });
    }
}

export default Landing;